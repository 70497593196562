exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-adding-gift-ideas-has-just-become-even-quicker-and-easier-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/adding-gift-ideas-has-just-become-even-quicker-and-easier.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-adding-gift-ideas-has-just-become-even-quicker-and-easier-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-applications-are-dead-long-live-pwas-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/applications-are-dead-long-live-pwas.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-applications-are-dead-long-live-pwas-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-duplicate-gifts-now-a-thing-of-the-past-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/duplicate-gifts-now-a-thing-of-the-past.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-duplicate-gifts-now-a-thing-of-the-past-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-swaver-2-0-our-biggest-update-yet-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/swaver-2-0-our-biggest-update-yet.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-swaver-2-0-our-biggest-update-yet-md" */),
  "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-turbocharge-your-wishlists-with-the-latest-version-of-swaver-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/opt/build/repo/content/blog/turbocharge-your-wishlists-with-the-latest-version-of-swaver.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-opt-build-repo-content-blog-turbocharge-your-wishlists-with-the-latest-version-of-swaver-md" */)
}

